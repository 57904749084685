@import '@angular/cdk/overlay-prebuilt.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import "./assets/variables/variables";

/* You can add global styles to this file, and also import other style files */
//thead {
//  tr:first-child {
//    th:first-child {
//      position: sticky;
//      left: 0;
//    }
//  }
//
//  tr:last-child {
//    th:last-child {
//      position: sticky;
//      right: 0;
//    }
//  }
//}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

app-root .modal {
  flex: 1;
  min-height: 0;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr) max-content;
  &-parent {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }
  &-title {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 15px;
  }
  &-content {
    margin-right: -24px;
    &_box {
      padding-right: 24px;
    }
  }

  &-footer {
    margin: 0 -24px;
    padding: 24px;
  }
}


.select {
  background-color: var(--tui-background-base);
  min-height: 56px;
  border-radius: 10px;
  &-body {
    &-bottom {
      .select-dropdown {
        margin-top: 10px;
      }
    }

    &-top {
      .select-dropdown {
        margin-bottom: 10px;
      }
    }
  }

  &-label {
    color: var(--tui-text-secondary);
    font: var(--tui-font-text-m);
    position: absolute;
    top: 50%;
    left: var(--tui-padding-l);
    transform: translateY(-50%);
    transition-duration: var(--tui-duration, .3s);
    transition-timing-function: ease-in-out;
    &-active {
      font-size: .8156rem;
      top: 6px;
      transform: translateY(0);
    }
  }

  &-origin {
    position: relative;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 39px;
    padding: 18px var(--tui-padding-l) 0;
    cursor: pointer;
    background-color: var(--select-bg);
    border-radius: 10px;
    box-shadow: none;
    min-height: 56px;
    transition: .15s ease-in-out;
    &::after {
      transition-property: color;
      transition-duration: var(--tui-duration, 300ms);
      transition-timing-function: ease-in-out;
      color: var(--tui-border-normal);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: "";
      border-radius: 10px;
      border: 1px solid currentColor;
      pointer-events: none;
    }
    &.readonly {
      opacity: 0.8;
      cursor: default;
    }
    &:not(.readonly):hover {
      background-color: var(--tui-background-neutral-1-hover);
    }
    &.focus {
      border-color: var(--tui-text-action);
    }

    .select-loader {
      margin-left: auto;
    }
    button {
      height: 100%;
      border: none;
      outline: none;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--tui-text-secondary);
    }

    &.multiple-selected {
      .select-origin-content {
        margin-top: 2px;
      }
    }

    &-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 3px 5px;
      &-remove {
        width: 39px;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #3C476066;
        cursor: pointer;
        margin-left: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 39px;
      }
      &-selected {
        font-size: 1rem;
        color: var(--tui-text-primary);
        &.chip {
          padding: 5px .75rem;
          border-radius: 2rem;
          color: var(--tui-text-primary);
          background: var(--tui-status-neutral);
          display: flex;
          align-items: center;
          button {
            width: 12px;
            height: 12px;
            border: none;
            outline: none;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--tui-text-secondary);
            cursor: pointer;
            i {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  &-overlay {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.select-body-top {
      flex-direction: column-reverse;
    }
    .select-origin {
      &:hover {
        background-color: var(--tui-background-neutral-1-hover) !important;
      }
    }
  }

  &-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: var(--tui-padding-l);
  }

  &-option {
    height: 46px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--tui-text-primary);
    font-size: 16px;
    padding: 0 10px;
    transition: .3s;
    &:hover {
      background: var(--tui-background-neutral-1);
    }
  }

  &-dropdown {
    background: var(--select-bg);
    border-radius: var(--tui-radius-m);
    border: 1px solid var(--tui-border-normal);;

    &-search {
      padding: 0.5rem 0;
      width: calc(100% - 2rem);
      margin: 0 auto 0.5rem;
      display: block;
      input {
        width: 100%;
      }

      & + .select-dropdown-options {
        min-height: calc(254px - 0.5rem - 0.5rem) !important;
      }
    }
    &-options {
      &.not-full {
        &.has-filter {
          min-height: calc((46px * var(--select-elements)) + 56px + 8px);
          max-height: calc((46px * var(--select-elements)) + 56px + 8px);
          .p-tree {
            height: calc(((46px * var(--select-elements)) + 56px) - 0.5rem) !important;
          }
        }

        &:not(.has-filter) {
          min-height: calc(46px * var(--select-elements) + 8px);
          max-height: calc(46px * var(--select-elements) + 8px);

          .p-tree {
            height: calc((46px * var(--select-elements)) - 0.5rem) !important;
          }
        }
      }
      &:not(.not-full) {
        min-height: 307px;
      }
      .p-tree {
        padding: 0;
        height: 300px;
        border: none;
        border-radius: 0;
        background: var(--select-bg);
        &-filter-container {
          margin: 0 auto 12px;
          &::after {
            transition-property: color;
            transition-duration: var(--tui-duration, 300ms);
            transition-timing-function: ease-in-out;
            color: var(--tui-border-normal);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: "";
            border-radius: 10px;
            border: 1px solid currentColor;
            pointer-events: none;
          }
          input {
            background-color: var(--tui-background-base-alt);
            color: var(--tui-text-secondary);
            border-radius: 10px;
            height: 44px;
            border: none;
            padding: 0 40px 0 12px;
            &:focus {
              border: none;
              box-shadow: none;
            }
          }
          .p-tree-filter-icon {
            transform: translateY(-50%);
          }
        }

        .p-treenode-content {
          height: 100%;
          min-height: 46px;
          color: var(--tui-text-primary);
          box-shadow: none !important;
          border: none !important;
          &.p-highlight {
            background: var(--tui-background-neutral-1);
            button {
              color: var(--tui-text-secondary);
            }
          }
          &:hover {
            background: var(--tui-background-neutral-1);
          }

          button {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            margin-right: 10px;
            color: var(--tui-text-secondary);
            &:hover {
              background: transparent;
              color: var(--tui-text-primary);
            }
          }
        }
      }
      &.selected-dropdown-option-filter {
        padding: 4px 6px;
        .p-tree {
          padding: 0;
          height: calc(254px - 0.5rem);
          border: none;
          position: relative;

          .p-tree-empty-message {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            trasnform: translateY(-50%);
            font-size: 1rem;
            color: var(--tui-text-secondary);
            text-align: center;
          }
        }
      }
    }
  }
}

.p-chip {
  gap: 3px;
  padding: 0 4px;
  font-size: 14px;
}

app-select {
  .p-scroller {
    &::-webkit-scrollbar {
      width: 13px;
      height: 13px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #000000;
    }
    &::-webkit-scrollbar-track {
      background: #666666;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
      background: #666666;
    }
    &::-webkit-scrollbar-track:active {
      background: #333333;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
}

app-table {
  th {
    background-color: var(--table-bg);
    color: var(--table-title-color);
    font-size: 14px;
    font-weight: 600;
    height: 48px;
    padding: 0 14px;
    border: 1px solid var(--table-border-color);
  }

  .p-treetable-scrollable-header {
    background: var(--tui-background-base);
  }

  .p-treetable-scrollable-body {
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      width: 6px; /* Устанавливаем тонкий скроллбар */
    }

    &::-webkit-scrollbar-track {
      background-color: transparent; /* Убираем фон трека */
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3); /* Задаем цвет и прозрачность скроллбара */
      border-radius: 4px; /* Скругляем углы для мобильного вида */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.5); /* Темнее при наведении */
    }

    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  }

  .no-scroll {
    .p-treetable-scrollable-header-box {
      padding-right: 0 !important;
    }
  }

  tfoot td, .p-treetable-footer, .p-treetable-scrollable-footer, {
    background-color: var(--table-bg);
    color: var(--table-title-color);
    border-color: var(--table-border-color) !important;
  }

  tr {
    max-height: 60px;
  }

  td {
    height: 60px;
    font-size: 14px;
  }

  table, tbody, thead, col, tr, td, th {
    border-color: var(--table-border-color) !important;
  }

  tbody, tfoot {
    td {
      border-left: 1px solid var(--table-border-color);
      border-right: 1px solid var(--table-border-color);
      background: var(--tui-background-base);
      color: var(--tui-text-primary);
    }
  }

  .has-child {
    th {
      &:first-child {
        padding: 0;
        background: var(--tui-background-base);
        border: none;
      }
    }

    tbody {
      tr:first-child {
        td:first-child {
          .table-toggler {
            border-top: 1px solid var(--table-border-color);
          }
        }
      }
      td {
        &:first-child {
          padding: 0;
          border: none;

          p-treetabletoggler {
            width: 100%;
            height: 100%;
            button {
              margin: 0 !important;
              width: 100%;
              height: 100%;

              &:hover {
                background-color: transparent;
              }
            }
          }
        }

        &:nth-child(2) {
          border-left: none;
        }
      }

      .table-toggler {
        height: 100%;
        border: 1px solid var(--table-border-color);
        border-top: none;
        background: var(--table-bg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
      }
    }

    td, th {
      &:first-child {
        position: sticky;
        left: 0;
        background: var(--tui-background-base);
      }
    }
  }

  .shadow {
    &-left {
      //position:relative;
      //&::after {
      //  content: '';
      //  display: block;
      //  position: absolute;
      //  top: 0;
      //  left: 0;
      //  bottom: 0;
      //  width: 10px;
      //  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #616161 100%);
      //  z-index: 23;
      //}
    }
  }
}

.p-tree {
  border: none;

  &-filter-container {
    margin-bottom: 10px;
    input {
      height: 32px;
      padding: 0 30px 0 12px;
    }

    searchicon {
      svg {
        transform: translateY(-50%);
      }
    }
  }
}

.p-treenode {
  &-children {
    padding-left: 20px;
  }

  &-content {
    .p-checkbox {
      margin-right: 8px;
    }
  }
}

[tuiTheme=dark] {
  tui-dropdown {
    background-color: #292D33;
  }
  tui-calendar {
    --tui-background-neutral-1: #FFFFFF29;
  }
  .p-tree {
    background: var(--select-bg);
    color: var(--tui-text-primary);
    input {
      background: var(--tui-background-neutral-1);
      color: var(--tui-text-primary);
    }

    .p-treenode-content {
      outline: none !important;
      &:hover {
        background: var(--tui-background-neutral-1);
        color: var(--tui-text-primary);
      }
    }

    .p-highlight, .p-checkbox-box {
      background: var(--tui-background-neutral-1);
      color: var(--tui-text-primary);
      outline: none !important;
    }
  }
}

[tuiAppearance][data-appearance=textfield] {
  --t-shadow: none;
}

@media (hover: hover) {
  [tuiAppearance][data-appearance=textfield][tuiWrapper]:hover:not(._no-hover),
  [tuiAppearance][data-appearance=textfield][tuiWrapper][data-state=hover],
  [tuiAppearance][data-appearance=textfield]:-webkit-any(a, button, select, textarea, input, label):hover:not(:disabled):not([data-state]){
    --t-shadow: none;
  }

  [tuiWrapper][data-appearance=textfield]:hover:not(._no-hover), [tuiWrapper][data-appearance=textfield][data-state=hover] {
    box-shadow: none;
  }
}

