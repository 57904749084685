:root {
  --title-color: #081047E5;
  --text-color-secondary: #081047A6;
  --primary-color: #2968DC;

  --table-bg: #F6F6F6;
  --table-title-color: #343A40;
  --table-border-color: #E0E0E0;

  --progress-bg: #dfdfdf;
  --progress-delimenter-bg: #B0B0B0;
  --progress-over-bg: #B12020;

  --progress-hover-block-bg: #F6F6F6;

  --progress-success-expenses-bg: #4AC99B;
  --progress-warning-expenses-bg: #FFC24A;
  --progress-error-expenses-bg: #FF8761;

  --progress-success-cost-bg: #30B283;
  --progress-warning-cost-bg: #F4A60D;
  --progress-error-cost-bg: #F45725;
  --select-bg: var(--tui-background-base);
  --ds-background-input: var(--tui-background-base);
  --ds-background-input-hovered: var(--tui-background-base);
  --ds-surface: var(--tui-background-base);
  --ds-border-radius-100: 10px;
  --ds-border: #ffffff24;

  [tuiTheme=dark] {
    --title-color: #fff;
    --text-color-secondary: #FFFFFFB8;
    --tui-background-neutral-1: #292D33;
    --table-bg: #1E2125;
    --table-title-color: #FFFFFF;
    --table-border-color: #292D33;
    --tui-text-action: #669EF8;
    --tui-border-normal: #303236;
    --progress-bg: #1e1d1d;
    --progress-hover-block-bg: #252424;
    --select-bg: #292D33;
    --tui-background-base: #1D2125;
    --ds-background-input: #303236;
    --ds-background-input-hovered: #303236;
    --ds-surface: #303236;
    .jql-editor {
      p {
        color: #f2f2f2
      }
    }
  }
}
